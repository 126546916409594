import imageCompression from "browser-image-compression";

export const compressImage = async (file: File, options: any = undefined): Promise<File> => {
    const opts = options ? options : {
        maxSizeMB: Infinity,
        maxWidthOrHeight: 640,
        useWebWorker: true,
    }

    return await imageCompression(file, opts);  
}