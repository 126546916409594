import { makeAutoObservable } from 'mobx';
import { toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { EventClient } from './EventClient';
import { IEventsDTO } from './EventDTO';

class EventModel {
  private events: IEventsDTO[] = [];
  private evnt: IEventsDTO | null = null;
  private eventsClient: EventClient = new EventClient();

  constructor() {
    makeAutoObservable(this);
  }

  setEvents = (events: Array<IEventsDTO>): void => {
    this.events = events;
  }

  private setEvent = (evnt: IEventsDTO): void => {
    this.evnt = evnt;
  };

  setName = async (name: string): Promise<void> => {
    const evntCopy: IEventsDTO | null = this.get();
    if (! evntCopy)
      throw new Error('Event is null');

    evntCopy.eventName = name;
    await this.eventsClient.update(evntCopy);
  };

  create = async (email: string | undefined): Promise<IEventsDTO> => {
    const eventsCopy = this.getEvents();
    const eventDTO: IEventsDTO = {
      eventUUID: uuidv4(),
      eventName: "New Album",
      email: email,
    };
    eventsCopy.push(eventDTO);
    await this.eventsClient.create(eventDTO);
    this.setEvents(eventsCopy);

    return eventDTO;
  }

  delete = async (eventUUID: string): Promise<void> => {
    const eventDTO: IEventsDTO = {
      eventUUID: eventUUID,
    };
    await this.eventsClient.delete(eventDTO);

    const eventsCopy: IEventsDTO[] = this.getEvents();
    const index: number = eventsCopy.findIndex((evnt: IEventsDTO) => { return evnt.eventUUID === eventUUID });
    eventsCopy?.splice(index, 1);
    this.setEvents(eventsCopy);
  }

  get = (): IEventsDTO | null => {
    return toJS(this.evnt);
  }

  getEvents = (): IEventsDTO[] => {
    return toJS(this.events);
  }

  getName = (): string | undefined => {
    return this.get()?.eventName;
  };

  getUUID = (): string | undefined => {
    return this.get()?.eventUUID;
  }

  getImageURLs = (): string[] | undefined => {
    return this.get()?.local?.imageURLs;
  }

  getThumbnailURLs = (): string[] | undefined => {
    return this.get()?.local?.imageThumbURLs;
  }

  addImage = async (fileName: string) => {
    if (!this.get()) {
      throw new Error("Event is null while updating event");
    }

    let evntCopy = toJS(this.get());
    if (!evntCopy) {
      throw new Error("evnt is null");
    }

    if (!evntCopy?.data) {
      const data: { imageNames: string[] } = {
        imageNames: [],
      };
      evntCopy.data = data;
    }

    evntCopy?.data?.imageNames.unshift(fileName);
    evntCopy = await this.loadThumbnailURL(evntCopy, 0);
    evntCopy = await this.loadImageURL(evntCopy, 0);

    await this.eventsClient.update(evntCopy);

    this.setEvent(evntCopy);
  }

  deleteImage = async (imageIndex: number): Promise<boolean | void> => {
    let evntCopy: IEventsDTO | null = toJS(this.get());
    if (!evntCopy) {
      throw new Error('evnt is null');
    }
    evntCopy?.data?.imageNames.splice(imageIndex, 1);
    evntCopy?.local?.imageThumbURLs?.splice(imageIndex, 1);
    evntCopy?.local?.imageURLs?.splice(imageIndex, 1);
    evntCopy = this.resetCoverImage(evntCopy, imageIndex);

    if (!evntCopy) {
      throw new Error("evnt should not be null");
    }

    await this.eventsClient.update(evntCopy);

    this.setEvent(evntCopy);
  }

  selectEvent = (eventUUID: string | undefined) => {
    const evnt: IEventsDTO = this.getEvents().filter(item => item.eventUUID === eventUUID)[0];     
    this.setEvent(evnt);
  }

  loadThumbnailURL = async (evnt: IEventsDTO, imageIndex: number): Promise<IEventsDTO> => {
    const iconURL: string = await this.eventsClient.fetchThumbnailURL(evnt, imageIndex);

    let urls = evnt?.local?.imageThumbURLs;
    if (!urls) {
      urls = [iconURL];
    } else if (imageIndex >= urls?.length) {
      urls?.push(iconURL);
    } else {
      urls?.splice(imageIndex, 0, iconURL);
    }
    evnt.local = {
      ...evnt.local,
      imageThumbURLs: urls
    }

    if (imageIndex == 0) {
      evnt.local = {
        ...evnt.local,
        coverImageURL: urls[0]
      };
    }

    return evnt;
  };

  loadImageURL = async(evnt: IEventsDTO, imageIndex: number): Promise<IEventsDTO> => {
    const iconURL: string = await this.eventsClient.fetchURL(evnt, imageIndex);
    let urls = evnt?.local?.imageURLs;
    if (!urls) {
      urls = [iconURL];
    } else if (imageIndex >= urls?.length) {
      urls?.push(iconURL);
    } else {
      urls?.splice(imageIndex, 0, iconURL);
    }
    evnt.local = {
      ...evnt.local,
      imageURLs: urls
    }

    return evnt;
  };

  private resetCoverImage = (evntCopy: IEventsDTO | null, index: number): IEventsDTO | null => {
    if (index === 0 && evntCopy?.local?.coverImageURL) {
      const imageThumbURLs = evntCopy?.local?.imageThumbURLs;
      if (evntCopy?.local?.imageThumbURLs && imageThumbURLs && imageThumbURLs.length > 0) {
        evntCopy.local.coverImageURL = evntCopy.local.imageThumbURLs[0];
      }
    }
    return evntCopy;
  }
}

export const eventModel = new EventModel();
