import Button from "@mui/material/Button";
import { APP_NAME } from "../../config/config";

export const Auth0LoginButton = (props: any) => {
  const buttonName: string = props.name;
  const sx = props.sx;
  const variant = props.variant;

  return (
    <Button
      variant={variant}
      color="primary"
      size="small"
      sx={sx}
      href={`${window.location.origin}/${APP_NAME}`}
    >
      {buttonName}
    </Button>
  );
};
