import { toJS } from 'mobx';
import { makeAutoObservable } from 'mobx';
import { IAccountsDTO } from './AccountsDTO';

class AccountModel {
  private account: IAccountsDTO | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getAccount = (): IAccountsDTO | null => {
    return toJS(this.account);
  }

  setAccount = (account: IAccountsDTO): void => {
    this.account = account;
  };
}

export const accountModel = new AccountModel();
