import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import { RemoteImage } from "../img/RemoteImage";
import { Typography } from "@mui/material";

export const Timeline = () => {
  return (
    <>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="2011 - present"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<SportsKabaddiIcon />}
        >
          <h3 className="vertical-timeline-element-title">CUTA Games 2024</h3>
          <h4 className="vertical-timeline-element-subtitle">San Jose, CA</h4>
          <RemoteImage
            iconURL="https://static.wixstatic.com/media/0b0019_71c1e5daaf1642a998fcb0e1abc8b988.jpg/v1/fill/w_960,h_640,al_c,q_85,enc_auto/0b0019_71c1e5daaf1642a998fcb0e1abc8b988.jpg"
            width={290}
          />
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(233, 30, 99)" }}
          date="2010 - 2011"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<SportsKabaddiIcon />}
        >
          <h3 className="vertical-timeline-element-title">State 2024</h3>
          <h4 className="vertical-timeline-element-subtitle">Fresno, CA</h4>
          <RemoteImage
            iconURL="https://static.wixstatic.com/media/0b0019_0d7d8d8fd48642d084b583d067a1269a~mv2.jpg/v1/fill/w_864,h_576,al_c,lg_1,q_85,enc_auto/0b0019_0d7d8d8fd48642d084b583d067a1269a~mv2.jpg"
            width={290}
          />
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(233, 30, 99)" }}
          date="2008 - 2010"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<SportsKabaddiIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Stanford Rumble 2024
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Palo Alot, CA</h4>
          <RemoteImage
            iconURL="https://static.wixstatic.com/media/0b0019_06bd3c395c4d4f21af389f421facf21b.jpg/v1/fill/w_960,h_540,al_c,q_85,enc_auto/0b0019_06bd3c395c4d4f21af389f421facf21b.jpg"
            width={290}
          />
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="2006 - 2008"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<SportsKabaddiIcon />}
        >
          <h3 className="vertical-timeline-element-title">Lions Cup 2024</h3>
          <h4 className="vertical-timeline-element-subtitle">Santa Cruz, CA</h4>
          <EmojiEventsIcon />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="April 2013"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<SportsKabaddiIcon />}
        >
          <h3 className="vertical-timeline-element-title">Nationals 2024</h3>
          <h4 className="vertical-timeline-element-subtitle">Dallas, TX</h4>
          <EmojiEventsIcon />
          <EmojiEventsIcon />
          <EmojiEventsIcon />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="November 2012"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<SportsKabaddiIcon />}
        >
          <h3 className="vertical-timeline-element-title">CUTA Games 2023</h3>
          <h4 className="vertical-timeline-element-subtitle">San Jose, CA</h4>
          <EmojiEventsIcon />
          <EmojiEventsIcon />
          <EmojiEventsIcon />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(233, 30, 99)" }}
          date="2002 - 2006"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<SportsKabaddiIcon />}
        >
          <h3 className="vertical-timeline-element-title">Lions Cup 2023</h3>
          <h4 className="vertical-timeline-element-subtitle">
            San Francisco, CA
          </h4>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: "white", color: "#fff" }}
          icon={<SportsKabaddiIcon sx={{ color: "black" }} />}
        />
      </VerticalTimeline>
    </>
  );
};
