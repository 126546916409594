const INDEFINETLY: number = 20290101;

export const eventsData = [
    {
      id: "1",
      title: "Beginners",
      start: "2024-09-16 16:30",
      end: "2024-09-16 17:10",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "2",
      title: "Team Youth",
      start: "2024-09-16 17:10",
      end: "2024-09-16 18:30",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "3",
      title: "Team B",
      start: "2024-09-16 18:30",
      end: "2024-09-16 20:00",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "4",
      title: "Women",
      start: "2024-09-16 20:00",
      end: "2024-09-16 21:30",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },

    {
      id: "5",
      title: "Team Youth",
      start: "2024-09-17 16:30",
      end: "2024-09-17 17:50",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "6",
      title: "Team B",
      start: "2024-09-17 17:50",
      end: "2024-09-17 19:20",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "7",
      title: "Team A",
      start: "2024-09-17 19:20",
      end: "2024-09-17 21:00",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },

    {
      id: "8",
      title: "Small Group",
      start: "2024-09-18 17:00",
      end: "2024-09-18 18:00",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "9",
      title: "Small Group",
      start: "2024-09-18 18:00",
      end: "2024-09-18 19:00",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "10",
      title: "Small Group",
      start: "2024-09-18 19:00",
      end: "2024-09-18 20:00",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "11",
      title: "Open Floor",
      start: "2024-09-18 20:00",
      end: "2024-09-18 21:30",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },

    {
      id: "12",
      title: "Beginners",
      start: "2024-09-19 16:30",
      end: "2024-09-19 17:10",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "13",
      title: "Team Youth",
      start: "2024-09-19 17:10",
      end: "2024-09-19 18:10",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "14",
      title: "Team B",
      start: "2024-09-19 18:10",
      end: "2024-09-19 19:10",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "15",
      title: "Team A",
      start: "2024-09-19 19:10",
      end: "2024-09-19 21:00",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },

    {
      id: "16",
      title: "Beginners",
      start: "2024-09-20 16:30",
      end: "2024-09-20 17:10",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "17",
      title: "Team B",
      start: "2024-09-20 17:10",
      end: "2024-09-20 18:20",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "18",
      title: "Team A",
      start: "2024-09-20 18:20",
      end: "2024-09-20 20:00",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },

    {
      id: "19",
      title: "TKD Privates",
      start: "2024-09-21",
      end: "2024-09-21",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },

    {
      id: "20",
      title: "Team Youth/B",
      start: "2024-09-22 17:00",
      end: "2024-09-22 18:30",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
    {
      id: "21",
      title: "Team A/B",
      start: "2024-09-22 18:30",
      end: "2024-09-22 20:30",
      rrule: `FREQ=WEEKLY;UNTIL=${INDEFINETLY}`,
    },
];