import { useCalendarApp, ScheduleXCalendar } from "@schedule-x/react";
import { createViewWeek } from "@schedule-x/calendar";
import "@schedule-x/theme-default/dist/index.css";
import { createEventRecurrencePlugin } from "@schedule-x/event-recurrence";

import { Dates } from "../../utils/Dates";
import { eventsData } from "./EventsData";

export const ScheduleXCal = () => {
  const calendar = useCalendarApp({
    plugins: [createEventRecurrencePlugin()],
    firstDayOfWeek: 0,
    isDark: true,
    dayBoundaries: {
      start: "15:00",
      end: "22:00",
    },
    minDate: Dates.getYMD(),
    maxDate: "2029-01-01",
    weekOptions: {
      gridHeight: 480,
      nDays: 7,
      eventWidth: 95,

      /**
       * Intl.DateTimeFormatOptions used to format the hour labels on the time axis
       * Default: { hour: 'numeric' }
       */
      timeAxisFormatOptions: { hour: "2-digit", minute: "2-digit" },
      showLocation: true,
    },
    views: [
      //createViewDay(),
      createViewWeek(),
      //createViewMonthGrid(),
      //createViewMonthAgenda(),
    ],
    events: eventsData,
  });

  return (
    <div id="calendar">
      <ScheduleXCalendar calendarApp={calendar} key={0} />
    </div>
  );
};
