import { SERVER_HTTPS_ENDPOINT } from "../config/config";
import { ServerClient } from "./ServerClient";
import { IAccountsDTO } from "./AccountsDTO";

export class AccountsClient extends ServerClient {
  constructor(token: string = "") {
    super(token);
  }

  async get(dto: IAccountsDTO): Promise<IAccountsDTO> {
    if (! dto.email) {
      throw new Error("ERROR: email must be provided");
    }
    console.debug('accounts client - get - ', dto.email);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/account/get?email=${encodeURIComponent(dto.email).replace(/\./g, '%2E')}`;
    return await this.fetchJSONCall(url, this.getOptions(url));
  }

  async create(dto: IAccountsDTO): Promise<IAccountsDTO> {
    console.debug('accounts client - create - ', dto.email);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/account/create`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }

  async update(dto: IAccountsDTO): Promise<IAccountsDTO> {
    console.debug('accounts client - update - ', dto.email);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/account/update`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }

  async delete(dto: IAccountsDTO): Promise<IAccountsDTO> {
    console.debug('accounts client - delete - ', dto.email);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/account/delete`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }
}
