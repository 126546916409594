import { makeAutoObservable } from 'mobx';
import { toJS } from 'mobx';
import { eventModel } from './EventModel';
import { IProjectsDTO } from './ProjectDTO';
import { ProjectsClient } from './ProjectsClient';
import { IEventsDTO } from './EventDTO';

class ProjectModel {
  private project: IProjectsDTO | null = null;
  private projects: IProjectsDTO[] = [];
  private projectsClient = new ProjectsClient();

  constructor() {
    makeAutoObservable(this);
  }

  getProjects = (): IProjectsDTO[] => {
    return toJS(this.projects);
  }

  getProject = (): IProjectsDTO | null => {
    return toJS(this.project);
  }

  setProject = (project: IProjectsDTO | null): void => {
    this.project = project;
  };

  setProjects = (projects: Array<IProjectsDTO>): void => {
    this.projects = projects;
  };

  private getProjectByUUID = (projectUUID: string | undefined): IProjectsDTO => {  
    const projs = this.getProjects();
    return projs.filter(item => item.projectUUID === projectUUID)[0];
  }

  selectProject = (projectUUID: string | undefined) => {
    const proj: IProjectsDTO = this.getProjectByUUID(projectUUID);  
    this.setProject(proj);
  };

  addEvent = async (email: string | undefined) => {
    const eventDTO: IEventsDTO = await eventModel.create(email);

    const project: IProjectsDTO | null = this.getProject();
    project?.data?.eventsUUIDList?.push(eventDTO.eventUUID);
    await this.projectsClient.update(project);
    this.setProject(project);
  };

  deleteEvent = async (eventUUID: string) => {
    await eventModel.delete(eventUUID);

    const project: IProjectsDTO | null = this.getProject();
    const index: number = project?.data?.eventsUUIDList?.findIndex((evntID: string) => { return evntID === eventUUID });
    project?.data?.eventsUUIDList?.splice(index, 1);
    
    await this.projectsClient.update(project);
    this.setProject(project);
  };
}

export const projectsModel = new ProjectModel();
