import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import {
  Auth0ProviderWrapper,
  Auth0LoginWrapper,
  APP_NAME,
} from "@azulejo/common";

const LandingPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/LandingPage`)
);
const ProjectsPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/ProjectsPage`)
);
const EventPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/EventPage`)
);
const EventsPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/EventsPage`)
);
const CalendarPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/CalendarPage`)
);
const AboutPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/AboutPage`)
);

export const ROOT_PATH = "/";

export const APP_PATH = `/${APP_NAME}`;
export const PROJECTS_PATH = APP_PATH;
export const EVENT_PATH = `${APP_PATH}/event`;
export const EVENTS_PATH = `${APP_PATH}/events`;
export const CALENDAR_PATH = `${APP_PATH}/calendar`;
export const ABOUT_PATH = `${APP_PATH}/about`;

const AppRouter = createBrowserRouter([
  {
    path: APP_PATH,
    element: <Auth0ProviderWrapper component={<Auth0LoginWrapper />} />,
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ProjectsPage />
          </Suspense>
        ),
      },
      {
        path: `${EVENTS_PATH}/:projectUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <EventsPage />
          </Suspense>
        ),
      },
      {
        path: `${EVENT_PATH}/:projectUUID/:eventUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <EventPage />
          </Suspense>
        ),
      },
      {
        path: `${CALENDAR_PATH}/:projectUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CalendarPage />
          </Suspense>
        ),
      },
      {
        path: `${ABOUT_PATH}/:projectUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AboutPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: `${ROOT_PATH}/about`,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AboutPage />
      </Suspense>
    ),
  },
  {
    path: `${ROOT_PATH}/calendar`,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CalendarPage />
      </Suspense>
    ),
  },
  {
    path: ROOT_PATH,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <LandingPage />
      </Suspense>
    ),
  },
]);

export default AppRouter;
