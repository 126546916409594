import { themeState } from "../../model/ModeModel";
import { createTheme } from "@mui/material/styles";
import getLPTheme from "./getLPTheme";

function createLPTheme() {
    const { mode } = themeState;
    return createTheme(getLPTheme(mode));    
}

function createDefaultTheme() {
    const { mode } = themeState;
    return createTheme({ palette: { mode } });
}

export function getTheme() {
    const { showCustomTheme } = themeState;
    return showCustomTheme ? createLPTheme() : createDefaultTheme();
}
