const Link = () => {
  return (
    <>
      <link rel="icon" href="%PUBLIC_URL%/logo_32x32.png" />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo_32x32.png" />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    </>
  );
};

export default Link;
