
export class FileNames {
    static getThumbnailName(fileName:string): string {
        const nameAndExt = this.splitFileNameAndExtension(fileName);
        return nameAndExt.name + "_thumbnail." + nameAndExt.extension;
    }

    private static splitFileNameAndExtension(fileName: string): { name: string; extension: string} {
        const lastDotIndex = fileName.lastIndexOf(".");
        if (lastDotIndex === -1 || lastDotIndex === 0) {
            return {
                name: fileName,
                extension: "",
            };
        }
        
        const name = fileName.substring(0, lastDotIndex);
        const extension = fileName.substring(lastDotIndex + 1);
        
        return {
            name: name,
            extension: extension,
        };
    }
}