import { Helmet } from "react-helmet";
import Metadata from "./Metadata";
import Link from "./Link";

export const Head = () => {
  return (
    <>
      <Helmet>
        <Metadata />
        <Link />
        <title>Azulejo.io</title>
      </Helmet>
    </>
  );
};
