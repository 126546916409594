import { SERVER_HTTPS_ENDPOINT } from "../config/config";
import { ServerClient } from "./ServerClient";
import { IAppDTO } from "./AppDTO";
import { IAccountsDTO } from "./AccountsDTO";

export class AppClient extends ServerClient {
  constructor(token: string = "") {
    super(token);
  }

  async get(dto: IAppDTO): Promise<string | void> {
    if (! dto.bucket || ! dto.key) {
        throw new Error("ERROR: bucket and key must be provided");
    }

    console.debug('app client - get - ', dto);
    const bucket = encodeURIComponent(dto.bucket).replace(/\./g, '%2E');
    const key = encodeURIComponent(dto.key).replace(/\./g, '%2E');
    const url = `${SERVER_HTTPS_ENDPOINT}/api/app/presigned-s3-url?bucket=${bucket}&key=${key}`;
    return await this.fetchCall(url, this.getOptions(url));
  }

  async getUserData(dto: IAccountsDTO): Promise<string> {
    if (! dto.email) {
      throw new Error("ERROR: email must be provided");
    }

    console.debug('app client - getUserData');
    const email = encodeURIComponent(dto.email).replace(/\./g, '%2E');
    const url = `${SERVER_HTTPS_ENDPOINT}/api/app/get-user-data?email=${email}`;
    const responseStr = await this.fetchCall(url, this.getOptions(url));
    if (!responseStr) {
      throw new Error("response is undefined");
    }
    const response = JSON.parse(responseStr);
    return response;
  }
}
