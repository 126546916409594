import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export const Auth0LoginWrapper = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, error, logout } =
    useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, error]);

  useEffect(() => {
    if (error) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }, [error, logout]);

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {isAuthenticated ? (
        <>
          <Outlet />
        </>
      ) : null}
    </>
  );
};
