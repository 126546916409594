import { useState, useCallback } from "react";
import { Button, Typography, Box, CardMedia, Stack } from "@mui/material";
import { AWSMultipartUpload } from "../io/AWSMultipartUpload";
import { compressImage } from "../img/BrowserImageCompression";
import { FileNames } from "../../utils/FileNames";
let { truncate } = require("lodash");

export const FileUploadCard = (props: any) => {
  const sx = props.sx;
  const keyPrefix: string = props.keyPrefix;
  const bucket: string = props.bucket;
  const callback: any = props.callback;

  const variant = "contained";

  const INPUT_ID = "file-upload";
  const CHOOSE_FILE_NAME = "Choose File";
  const DEFAULT_PROGRESS_COLOR = "text.secondary";

  const [buttonName, setButtonName] = useState<string>("...");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [file, setFile] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressColor, setProgressColor] = useState<string>(
    DEFAULT_PROGRESS_COLOR
  );
  const awsUploadClient = new AWSMultipartUpload();

  const uploadProgressCallback = (progressValue: number): void => {
    setUploadProgress(progressValue);
    console.log(progressValue);
  };

  const onChooseHandler = useCallback(async (): Promise<void> => {
    document.getElementById(INPUT_ID)?.click();
  }, []);

  const onChangeHandler = (evnt: any): void => {
    if (evnt.target.files.length === 0) {
      return;
    }
    const file = evnt.target.files[0];
    setFile(file);
    setIsButtonDisabled(false);
    setButtonName(truncate(file.name, { length: 15 }));
    setUploadProgress(0);
    setProgressColor(DEFAULT_PROGRESS_COLOR);
  };

  const onUploadHandler = async (): Promise<void> => {
    if (!file) return;

    setIsButtonDisabled(true);
    try {
      const resizedFile = await compressImage(file);
      const resizedFileName = FileNames.getThumbnailName(file.name);
      await awsUploadClient.upload(
        resizedFile,
        { s3Bucket: bucket, s3Key: `${keyPrefix}/${resizedFileName}` },
        uploadProgressCallback
      );

      await awsUploadClient.upload(
        file,
        { s3Bucket: bucket, s3Key: `${keyPrefix}/${file.name}` },
        uploadProgressCallback
      );
      console.log("Upload completed successfully!");
      setProgressColor("green");
      await callback(file.name);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsButtonDisabled(false);
      setProgressColor("red");
    }
  };

  return (
    <Stack
      direction="column"
      component={CardMedia}
      spacing={1}
      useFlexGap
      sx={{
        color: "inherit",
        p: 3,
        height: "300px",
        border: "1px solid",
        borderColor: "hsla(220, 25%, 25%, .3)",
        //backgroundImage: `url(${item.icon})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        boxShadow: "none",

        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <input
        id={INPUT_ID}
        type="file"
        onChange={onChangeHandler}
        style={{ display: "none" }}
      />
      <label htmlFor={INPUT_ID}>
        <Button
          variant={variant}
          sx={sx}
          color="primary"
          size="small"
          onClick={onChooseHandler}
        >
          <Typography>{CHOOSE_FILE_NAME}</Typography>
        </Button>
      </label>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 0,
          gap: 2,
        }}
      >
        <Button
          variant={variant}
          sx={sx}
          color="primary"
          size="small"
          onClick={onUploadHandler}
          disabled={isButtonDisabled}
        >
          <Typography>{buttonName}</Typography>
        </Button>
        <Typography sx={{ color: progressColor }}>{uploadProgress}%</Typography>
      </Box>
    </Stack>
  );
};
