import { themeState } from "../model/ModeModel";

export class ModeViewModel {
    localMode: string | undefined = undefined;
    expectedMode: string = "dark";

    constructor(expectedMode: string) {
        const { mode } = themeState;
        this.localMode = mode;
        this.expectedMode = expectedMode;
    }

    isInitialized() {
        return !this.localMode || this.localMode === this.expectedMode;
    }
}