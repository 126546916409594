
export class ServerClient {
  token: string;

  constructor(token: string) {
    this.token = token;
  }

  getOptions(url: string) {
    return {
      method: 'GET',
      url: url,
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    };
  }

  postOptions(url: string, data: any) {
    return {
      method: 'POST',
      url: url,
      headers: {
        authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
  }

  deleteOptions(url: string) {
    return {
      method: 'DELETE',
      url: url,
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    };
  }

  patchOptions(url: string, data: any) {
    return {
      method: 'PATCH',
      url: url,
      headers: {
        authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
  }

  async fetchJSONCall(url: string, options: any) {
    let result = await fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Response: not ok');
        }
        return res.text();
      })
      .then((data) => {
        return JSON.parse(data);
      })
      .catch((error) => {
        console.error('getAccount error:', error.message);
      });

    return result;
  }

  async fetchCall(url: string, options: any) {
    let result = await fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Response: not ok');
        }
        return res.text();
      })
      .catch((error) => {
        console.error('getAccount error:', error.message);
      });

    return result;
  }
}