import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, APP_NAME } from "../../config/config";

interface AuthProps {
  component: React.ReactNode;
}

export const Auth0ProviderWrapper = (props: AuthProps) => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin + `/${APP_NAME}`,
      }}
    >
      {props.component}
    </Auth0Provider>
  );
};
