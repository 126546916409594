import { SERVER_HTTPS_ENDPOINT, APP_NAME } from "../config/config";
import { ServerClient } from "./ServerClient";
import { AppClient } from "./AppClient";
import { IAppDTO } from "./AppDTO";
import { FileNames } from "../utils/FileNames";
import { IEventsDTO } from "./EventDTO";

export class EventClient extends ServerClient {
  constructor(token: string = "") {
    super(token);
  }

  async get(dto: IEventsDTO): Promise<IEventsDTO> {
    if (!dto.eventUUID) {
      throw new Error("ERROR: eventUUID not provided");
    }
    console.debug('events client - get - ', dto.eventUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/tornado-events/get?eventUUID=${encodeURIComponent(dto.eventUUID).replace(/\./g, '%2E')}`;
    return await this.fetchJSONCall(url, this.getOptions(url));
  }

  async create(dto: IEventsDTO): Promise<IEventsDTO> {
    console.debug('events client - create - ', dto.eventUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/tornado-events/create`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }

  async update(dto: IEventsDTO): Promise<IEventsDTO> {
    console.debug('events client - update - ', dto.eventUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/tornado-events/update`;

    let updateDTO: IEventsDTO = {
      eventUUID: dto?.eventUUID,
      update: {
        email: dto?.email,
        data: dto?.data,
        eventName: dto?.eventName
      },
    };

    return await this.fetchJSONCall(url, this.postOptions(url, updateDTO));
  }

  async delete(dto: IEventsDTO): Promise<IEventsDTO> {
    console.debug('events client - delete - ', dto.eventUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/tornado-events/delete`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }

  fetchURL = async (evnt: IEventsDTO, imageIndex: number): Promise<string> => {
    const appDTO: IAppDTO = { bucket: `${APP_NAME}-events`, key: `${evnt?.eventUUID}/${evnt?.data.imageNames[imageIndex]}` };
    let appClient = new AppClient();
    const iconURL: string | void = await appClient.get(appDTO);
    if (!iconURL) {
      throw new Error("url is undefined");
    }
    return iconURL;
  }

  fetchThumbnailURL = async (evnt: IEventsDTO, imageIndex: number): Promise<string> => {
      const thumbnailName = FileNames.getThumbnailName(evnt?.data.imageNames[imageIndex]);
      const appDTO: IAppDTO = { bucket: `${APP_NAME}-events`, key: `${evnt?.eventUUID}/${thumbnailName}` };
      let appClient = new AppClient();
      const iconURL: string | void = await appClient.get(appDTO);
      if (!iconURL) {
        throw new Error("thumbnail url is undefined");
      }
      return iconURL;
  }
}
