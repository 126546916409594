export const PublicImage = (props: any) => {
  let fileName: string = props.fileName;
  let iconURL = `${process.env.PUBLIC_URL}/${fileName}`;

  return (
    <>
      <img src={iconURL} alt="tornado-icon" {...props} />
    </>
  );
};
