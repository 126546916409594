export const ACCESS_TOKEN = "token";
export const POLLING_TIMEOUT = 7200 * 1000 // == 2 hours
export const UPLOAD_CHUNK_SIZE = 10 * 1024 * 1024; // 10MB
export const UPLOAD_PARALLEL_COUNT = 4;

// environment variables
export const APP_NAME = process.env.REACT_APP_APP_NAME ?? "azul";
export const REGION = process.env.REACT_APP_REGION ?? "us-west-2";
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? "";
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? "";
export const BUILD_CONFIGURATION = process.env.REACT_APP_BUILD_CONFIGURATION ?? "dev";
export const SERVER_HTTPS_ENDPOINT = process.env.REACT_APP_SERVER_HTTPS_ENDPOINT ?? "http://localhost:8080";
export const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL ?? "contact@azulejo.io";

export const UPLOAD_BUCKET = `${APP_NAME}-ingest`;
