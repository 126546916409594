import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { REGION, AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, UPLOAD_CHUNK_SIZE, UPLOAD_PARALLEL_COUNT } from "../../config/config";

interface S3Resource {
    s3Bucket: string;
    s3Key: string;
};

export class AWSMultipartUpload {
    s3Client: any = null;

    constructor() {
        this.s3Client = new S3Client({
            region: REGION,
            credentials: {
                accessKeyId: AWS_ACCESS_KEY_ID,
                secretAccessKey: AWS_SECRET_ACCESS_KEY
            },
            maxAttempts: 100
        });
    }

    async upload(file: File, s3Resource: S3Resource, uploadPorgressCallback: any) {
        const upload = new Upload({
            client: this.s3Client,
            params: {
                Bucket: s3Resource.s3Bucket,
                Key: s3Resource.s3Key,
                Body: file,
                ContentType: file.type
            },
            partSize: UPLOAD_CHUNK_SIZE,
            leavePartsOnError: true, // clean up parts if the upload fails
            queueSize: UPLOAD_PARALLEL_COUNT,
        });
    
        upload.on('httpUploadProgress', (progress: any) => {
            const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            uploadPorgressCallback(uploadProgress);
        });

        await upload.done();
    }
};
