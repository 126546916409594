import { useState } from "react";
import { Button, Typography, Box, Toolbar, Container } from "@mui/material";
import { AWSMultipartUpload } from "../io/AWSMultipartUpload";
import { UPLOAD_BUCKET } from "../../config/config";

export const FileUploadToolbar = (props: any) => {
  const sx = props.sx;
  const variant = props.variant;
  const toolbarStyles = props.toolbarStyles;

  const INPUT_ID = "file-upload";
  const UPLOAD_BUTTON_NAME = "Upload";
  const CHOOSE_FILE_NAME = "Choose File";

  const [buttonName, setButtonName] = useState<string>(CHOOSE_FILE_NAME);
  const [file, setFile] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const awsUploadClient = new AWSMultipartUpload();

  const uploadProgressCallback = (progressValue: number) => {
    setUploadProgress(progressValue);
    console.log(progressValue);
  };

  const onClickHandler = async () => {
    if (buttonName !== UPLOAD_BUTTON_NAME) {
      document.getElementById(INPUT_ID)?.click();
    } else {
      if (!file) return;
      try {
        await awsUploadClient.upload(
          file,
          { s3Bucket: UPLOAD_BUCKET, s3Key: file.name },
          uploadProgressCallback
        );
        console.log("Upload completed successfully!");
        setButtonName(CHOOSE_FILE_NAME);
        setUploadProgress(0);
        setFile(undefined);
      } catch (error) {
        console.error("Error uploading file:", error);
        console.log("Error uploading file. Please try again.");
      }
    }
  };

  const onChangeHandler = (evnt: any) => {
    setFile(evnt.target.files[0]);
    setButtonName(UPLOAD_BUTTON_NAME);
  };

  return (
    <Container maxWidth="sm">
      <Toolbar variant="regular" sx={toolbarStyles}>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            px: 0,
            gap: 2,
          }}
        >
          <input
            id={INPUT_ID}
            type="file"
            onChange={onChangeHandler}
            style={{ display: "none" }}
          />
          <label htmlFor={INPUT_ID}>
            <Button
              variant={variant}
              sx={sx}
              color="primary"
              size="small"
              onClick={onClickHandler}
            >
              <Typography>{buttonName}</Typography>
            </Button>
          </label>
          <>
            {file ? (
              <Typography sx={{ color: "text.secondary" }}>
                {file?.name}
              </Typography>
            ) : (
              <Typography sx={{ color: "text.secondary" }}>
                {"[no file selected]"}
              </Typography>
            )}
          </>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {uploadProgress > 0 && (
            <Typography sx={{ color: "text.secondary" }}>
              Upload Progress: {uploadProgress}%
            </Typography>
          )}
        </Box>
      </Toolbar>
    </Container>
  );
};
