import ReactDOM from "react-dom/client";
import App from "./app/App";
import { verifyMobXConfig /*,reportWebVitals */ } from "@azulejo/common";

export const root = true;

verifyMobXConfig();

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    //<React.StrictMode>
    <App />
    //</React.StrictMode>
  );
} else {
  console.error("Root element not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
